import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { AnchorTarget } from "@tyson-foods/react-component-library"

export default class PrivacyPolicyPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Privacy Policy</title>
          <meta
            name="description"
            content="Raised & Rooted's™ policy is to respect and protect the privacy of our users. This policy statement tells you how we collect information from you and how we use it."
          />
        </Helmet>
        <Layout>
          <div className="legal-container rich-text">
            <h1>Privacy Policy</h1>
            <p>
              <br />
            </p>
            <p>
              <em>
                This Privacy Notice applies to the websites, apps, platforms
                where it appears, as well as any other points of contact with
                the Company.
              </em>
            </p>
            <br />
            <br />

            <p>
              <strong>Last Updated Date:</strong> April 09, 2020
            </p>

            <p>
              <strong>Tyson Foods</strong> knows that you care how information
              about you is used and shared, and we appreciate your trust that we
              will do so carefully and sensibly. This Privacy Policy describes
              how we may treat personal information. Please read carefully. The
              practices described herein are subject to the applicable laws in
              the places in which we operate. By accessing and/or using our
              website, or by communicating with us in any manner, you represent
              that you have read and consent to the terms of this Privacy Policy
            </p>

            <br />

            <h3>Conditions of Use, Notices, and Revisions </h3>

            <p>
              If you choose to visit this website, app or platform, your visit
              and any dispute over privacy is subject to this Notice and our{" "}
              <a href="/terms-of-use">terms of use</a>, including limitations on
              damages, resolution of disputes, and application of the law of the
              state of Arkansas. If you have any concern about privacy at this
              website, app or platform, please contact us with a thorough
              description, and we will try to resolve it. Our business changes
              constantly, and our Privacy Notice and the{" "}
              <a href="/terms-of-use">terms of use</a> will change also. We may
              periodically update this Privacy Policy. Please reference the
              “LAST UPDATED” legend at the top of the page to see when this
              Privacy Policy was last revised. Any changes to this Privacy
              Policy will become effective when we post the revised Privacy
              Policy on our Sites. Your use of the Sites following these changes
              means that you accept the revised Privacy Policy.
            </p>

            <br />

            <p>How we may collect your information</p>

            <br />

            <ul>
              <li>
                <p>
                  <strong>Contact information</strong> that you provide to us
                  such as your name, email address, your phone number and
                  mailing address.
                </p>
              </li>
              <li>
                <p>
                  <strong>Information you submit or post</strong> such as online
                  job applications, or information you provide when you contact
                  us.
                </p>
              </li>
              <li>
                <p>
                  <strong>Demographic information</strong> like your age,
                  gender, date of birth, age range, or zip code.
                </p>
              </li>
              <li>
                <p>
                  <strong>Payment information</strong> such as your credit card
                  number and your billing and shipping information if you make a
                  purchase.
                </p>
              </li>
              <li>
                <p>
                  <strong>Other information</strong>. If you use our site, we
                  may collect information about the browser you're using and how
                  often you use our site. We might look at what site you came
                  from, or what site you visit when you leave us. If you use our
                  mobile apps, we may collect your GPS location. We might look
                  at how often you use the app and where you downloaded it. We
                  collect this information using the tracking tools described
                  below. To control those tools, please read the{" "}
                  <a href="#choices">CHOICES</a> section below.
                </p>
              </li>
            </ul>

            <br />

            <p>We may collect information in different ways.</p>

            <br />

            <ul>
              <li>
                <p>
                  <strong>Directly</strong>. For example, if you sign up for our
                  emails or apply for a job online. We also collect information
                  if you contact us online or enter a contest. We may also
                  collect information by providing you with surveys to complete.
                </p>
              </li>
              <li>
                <p>
                  <strong>Passively</strong>. We use tracking tools like browser
                  cookies and web beacons to collect information from you. We
                  collect information about users over time when you use this
                  site. We also collect this information from our apps. We may
                  have third parties collect personal information this way. For
                  example, you can use controls in your internet browser to
                  limit how the websites you visit are able to use cookies and
                  to withdraw your consent by clearing or blocking cookies.
                </p>
              </li>
              <li>
                <p>
                  <strong>Through third parties</strong>. For example, our
                  business partners may give us information about you. Social
                  media platforms may also give us information about you.
                </p>
              </li>
              <li>
                <p>
                  <strong>By combining information</strong>. For example, we may
                  combine information that we have collected offline with
                  information we collect online. Or we may combine information
                  we get from a third party with information we already have.
                </p>
              </li>
            </ul>

            <br />

            <p>We use information as disclosed and described here</p>

            <br />

            <ul>
              <li>
                <p>
                  <strong>To respond to your requests or questions.</strong> For
                  example, we may use your information to respond to your
                  customer feedback. We may also use your information to notify
                  you if you win a contest.
                </p>
              </li>
              <li>
                <p>
                  <strong>To improve our products and services.</strong> We may
                  use your information to make our website, app, platform, and
                  products better. We might use your information to customize
                  your experience with us.
                </p>
              </li>
              <li>
                <p>
                  <strong>For security purposes.</strong> We may use your
                  information to protect our company and our customers. We may
                  also use information to protect our sites and apps.
                </p>
              </li>
              <li>
                <p>
                  <strong>For marketing purposes.</strong> For example, we might
                  use your information to provide you with information about new
                  products and special offers. We might also use your
                  information to serve you ads about products and offers. These
                  might be third party offers or products we think you might
                  find interesting. We might tell you about new features or
                  updates. If you sign up with us, we'll enroll you in an email
                  newsletter. To learn about your choices for these
                  communications, read the <a href="#choices">CHOICES</a>{" "}
                  section below.
                </p>
              </li>
              <li>
                <p>
                  <strong>To communicate with you.</strong> For example, we may
                  contact you about your feedback or our relationship. We might
                  also contact you about this Privacy Policy or our Terms of
                  Use.
                </p>
              </li>
              <li>
                <p>
                  <strong>We may use push notifications on our apps.</strong>{" "}
                  For example, we may contact you about your feedback or our
                  relationship. We might also contact you about this Privacy
                  Notice or our Conditions of Use.
                </p>
              </li>
              <li>
                <p>
                  <strong>We may use push notifications on our apps.</strong>{" "}
                  For example, we may send you push notifications about coupons
                  or other offers.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    We use information as otherwise permitted by law or as we
                    may notify you.
                  </strong>
                </p>
              </li>
            </ul>

            <br />

            <p>How we may share your information with third parties:</p>

            <br />

            <ul>
              <li>
                <p>
                  <strong>With your consent</strong>. We may, in some
                  circumstances, seek your consent before disclosing your{" "}
                  <strong>personal information</strong> to third parties,
                  including subsidiaries, affiliates, and unaffiliated third
                  parties. For example, we may obtain your consent in writing;
                  online, through “click-through” agreements; when you accept
                  the terms of use on our Websites; orally, either in person or
                  on the phone; or by other means.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    We will share information within the Tyson Foods family of
                    companies.
                  </strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    We will share information with certain third parties who
                    perform services on our behalf.
                  </strong>
                  For example, we share information with vendors who send emails
                  for us. We also share information with certain companies that
                  help us operate our websites, apps, and platforms, or run a
                  promotion or contest. If you are the winner of a sweepstakes
                  or contest, we may also share your information with anyone who
                  requests a winner's list.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    We may share your information with our business partners.
                  </strong>
                  For example, we might share information with third parties who
                  co-sponsor a promotion or contest. Some of these partners may
                  send you information about products or services by mail or
                  email.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    We may share information with third parties for marketing
                    purposes.
                  </strong>
                  These third parties may include our retail partners. It may
                  also include third parties in whose products or services we
                  believe you may be interested. These third parties may also
                  share your information with others. These third parties may
                  use the information for their own marketing purposes or the
                  marketing purposes of others. This may include the delivery of
                  interest based advertising.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    We will share information if we think we have to in order to
                    comply with the law or for similar purposes.
                  </strong>
                  For example, we will share information to respond to a court
                  order or subpoena. We may share information if a government
                  agency or investigative body requests it. We might share
                  information if we are investigating potential fraud. This
                  might include fraud we think has happened during a promotion.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    We may share information with a successor to all or part of
                    our business.
                  </strong>
                  For example, if part of our business is sold, we may include
                  customer information as part of that transaction.
                </p>
              </li>
            </ul>

            <br />

            <p>
              <AnchorTarget id="choices" />
              <u>CHOICES</u>
            </p>

            <p>What choices do you have about how we use your information?</p>

            <br />

            <ul>
              <li>
                <p>
                  <strong>
                    You can opt out of receiving our marketing emails.
                  </strong>
                  To stop receiving our promotional emails, follow the
                  instructions in any promotional message you get from us. Even
                  if you opt out of getting our marketing messages or
                  unsubscribe from our distribution lists, we will still send
                  you transactional messages. These include responses to your
                  questions.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    You can control certain cookies and tracking tools.
                  </strong>{" "}
                  To learn how to manage how we – and our vendors – use cookies
                  and other tracking tools, you can use controls in your
                  internet browser to limit how the websites you visit are able
                  to use cookies and to withdraw your consent by clearing or
                  blocking cookies.
                </p>
              </li>
              <li>
                <p>
                  <strong>You can control tools on your mobile device</strong>.
                  For example, you can turn off the GPS locator or push
                  notifications on your phone.
                </p>
              </li>
            </ul>

            <br />

            <p>Your California Privacy Rights & Disclosures</p>

            <br />

            <p>
              If you are a California resident, you have certain rights under
              California law, including but not limited to the California
              Consumer Privacy Act of 2018 (“CCPA”). You are also entitled to
              certain disclosures concerning our data practices. All phrases
              used in this section shall have the same meaning as those phrases
              are used under California law, including the CCPA.
            </p>

            <br />

            <p>
              <strong>Your Privacy Rights: California</strong>
            </p>

            <br />

            <p>
              <strong>
                <u>Shine the Light.</u>
              </strong>{" "}
              California residents who provide personal information in obtaining
              products or services for personal, family, or household use are
              entitled to request and obtain from us once a calendar year
              information about the customer information we shared, if any, with
              other businesses for their own direct marketing use. If
              applicable, this information would include the categories of
              customer information and the names and addresses of those
              businesses with which we shared customer information for the
              immediately prior calendar year (e.g., requests made in 2019 will
              receive information regarding 2018 sharing activities). If you are
              a California resident and would like to exercise these rights,
              please contact us at{" "}
              <a href="mailto:Privacy@tyson.com">Privacy@tyson.com</a> or call
              toll-free at 1-866-467-8688 and enter service code 262#. You also
              can contact us at:
            </p>

            <p>
              Tyson Foods, Inc. <br />
              Privacy c/o Ethics & Compliance Department <br />
              Mail Code CP006
              <br />
              2200 W. Don Tyson Parkway
              <br />
              Springdale, AR 72765-2020
              <br />
              or 1-866-467-8688 and enter service code 262#.
            </p>

            <p>
              Indicate in your communication that you are a California resident
              making a "Shine the Light" inquiry.
            </p>

            <p>
              <strong>
                <u>Right to Know.</u>
              </strong>{" "}
              As a California resident, you have a right to know:
            </p>

            <ul>
              <li>
                <p>
                  The categories of personal information we have collected,
                  sold, or disclosed for business purposes about you;
                </p>
              </li>
              <li>
                <p>
                  The categories of sources from which we collected your
                  personal information;
                </p>
              </li>
              <li>
                <p>
                  The business or commercial purpose for collecting or sharing
                  your personal information;
                </p>
              </li>
              <li>
                <p>
                  The categories of third parties with whom we have shared,
                  sold, or disclosed for business purposes your personal
                  information; and
                </p>
              </li>
              <li>
                <p>
                  The specific pieces of your personal information we have
                  collected.
                </p>
              </li>
            </ul>

            <br />

            <p>
              <em>Process To Submit A Request. </em>To submit a verified request
              for your personal information or to designate an authorized agent
              to submit a request on your behalf, you may click{" "}
              <a href="https://app.keysurvey.com/f/1452527/b91f/">here</a> or
              call toll-free at 1-866-467-8688 and enter service code 262# .
            </p>

            <br />

            <p>
              <em>Verification Method. </em>In order to ensure your personal
              information is not disclosed to unauthorized parties, and to
              protect against fraud, we will verify your identity before
              responding to your request. To verify your identity, we will
              generally match the identifying information provided in your
              request with the information we have on file about you. Depending
              on the sensitivity of the personal information requested, we may
              also utilize more stringent verification methods to verify your
              identity, including but not limited to requesting additional
              information from you and/or requiring you to sign a declaration
              under penalty of perjury.
            </p>

            <br />

            <p>
              <strong>
                <u>Right of Deletion.</u>
              </strong>
              You have a right to request that we delete the personal
              information we have collected from or about you.
            </p>

            <br />

            <p>
              <em>Process To Submit A Request. </em>To submit a verified request
              for your personal information or to designate an authorized agent
              to submit a request on your behalf, you may click{" "}
              <a href="https://app.keysurvey.com/f/1452527/b91f/">here</a> or
              call toll-free at 1-866-467-8688 and enter service code 262# .
            </p>

            <br />

            <p>
              <em>Verification Method. </em>. In order to ensure your personal
              information is not disclosed to unauthorized parties, and to
              protect against fraud, we will verify your identity before
              responding to your request. To verify your identity, we will
              generally match the identifying information provided in your
              request with the information we have on file about you. Depending
              on the sensitivity of the personal information requested, we may
              also utilize more stringent verification methods to verify your
              identity, including but not limited to requesting additional
              information from you and/or requiring you to sign a declaration
              under penalty of perjury.
            </p>

            <br />

            <p>
              <strong>
                <u>
                  Right to Opt-Out To The Sale of Your Personal Information.
                </u>
              </strong>{" "}
              As a California resident, you have the Right to Opt Out of the
              "sale" of your information. To submit a Right to Opt Out request
              or have a designated authorized agent submit a request, you may
              click <a href="https://app.keysurvey.com/f/1463959/4ffd/">here</a>{" "}
              or call us toll-free at 1-866-467-8688 and enter service code
              262#.{" "}
            </p>

            <br />

            <p>
              <strong>
                <u>Right of Non-Discrimination.</u>
              </strong>{" "}
              You have a right to exercise your rights under the CCPA without
              suffering discrimination. Accordingly, we will not discriminate
              against you in any way if you choose to exercise your rights
              pursuant to the CCPA.
            </p>

            <br />

            <p>
              <strong>
                <u>California Minors.</u>
              </strong>{" "}
              This website, app, or platform is not intended for minors. We do
              not knowingly collect the personal information of anyone under the
              age of 18. However, if you are a California resident under the age
              of 18, and you have publicly posted any information on our
              websites or apps, you also have the right to request and obtain
              removal of content or information you have publicly posted. If you
              wish to make such a request, please send an e-mail with a detailed
              description of the specific content or information to{" "}
              <a href="mailto:Privacy@tyson.com">Privacy@tyson.com</a>, or call
              toll-free at 1-866-467-8688 and enter service code 262# , or write
              to us at the address listed on this site.
              <br />
              These rights are subject to those exceptions and limitations
              recognized by applicable law.
            </p>

            <br />

            <p>
              <strong>California Disclosures</strong>
            </p>

            <br />

            <p>
              The following is a list of the categories of personal information
              we may have collected about California residents in the twelve
              months preceding the date this Privacy Notice was last updated,
              including the business or commercial purpose for said collection,
              the categories of sources from which we collected the personal
              information, and the categories of third parties with whom we have
              shared the personal information
            </p>

            <br />

            <p>
              <strong>Categories of Personal Information Collected</strong>
            </p>
            <ul>
              <li>
                <p>
                  Identifiers such as name, alias, address, unique personal
                  identifier, online identifier, IP address, email address,
                  account name, social security number, driver’s license number,
                  passport number, or other similar identifiers;
                </p>
              </li>
              <li>
                <p>
                  Categories of personal information as described in California
                  Civil Code Section 1798.80(e), which includes name, signature,
                  social security number, physical characteristics or
                  description, address, telephone number, passport number,
                  driver’s license or state identification card number,
                  insurance policy number, education, employment, employment
                  history, bank account number, credit card number, debit card
                  number, or any other financial information, medical
                  information, or health insurance information;
                </p>
              </li>
              <li>
                <p>
                  Characteristics of protected classifications under California
                  or federal law, including race, color, national origin, sex,
                  sexual orientation, gender, gender identity, gender
                  expression, religious creed, mental disability, physical
                  disability, medical condition, military / veteran status,
                  marital status, age, genetic characteristics, or ancestry;
                </p>
              </li>
              <li>
                <p>
                  Commercial information, including records of personal
                  property, products or services purchased, obtained, or
                  considered, or other purchasing or consuming histories or
                  tendencies;
                </p>
              </li>
              <li>
                <p>Biometric information;</p>
              </li>
              <li>
                <p>
                  Internet or other electronic network activity information,
                  including but not limited to, browsing history, search
                  history, and information regarding a consumer’s interaction
                  with an Internet Web site, application, or advertisement;
                </p>
              </li>
              <li>
                <p>Geolocation data;</p>
              </li>
              <li>
                <p>
                  Audio, electronic, visual, thermal, olfactory, or similar
                  information;
                </p>
              </li>
              <li>
                <p>Professional or employment-related information;</p>
              </li>
              <li>
                <p>
                  Education information, defined as information that is not
                  publicly available personally identifiable information as
                  defined in the Family Educational Rights and Privacy Act; and
                </p>
              </li>
              <li>
                <p>
                  Inferences drawn from any of the information identified above
                  used to create a profile about a consumer reflecting the
                  consumer’s preferences, characteristics, psychological trends,
                  predispositions, behavior, attitudes, intelligence, abilities,
                  and aptitudes.
                </p>
              </li>
            </ul>

            <br />

            <p>
              <strong>Categories of Sources.</strong> Categories of sources from
              which we may collect and/or share the personal information of
              California residents include, but may not be limited to:
            </p>
            <ul>
              <li>
                <p>the consumer directly</p>
              </li>
              <li>
                <p>subsidiaries and affiliate third parties</p>
              </li>
              <li>
                <p>non-affiliated third parties</p>
              </li>
              <li>
                <p>social medial networks</p>
              </li>
              <li>
                <p>service providers</p>
              </li>
            </ul>

            <br />

            <p>
              <strong>Business Purpose for Collection.</strong> The business
              purposes for which we may collect and/or share the personal
              information of California residents includes, but may not be
              limited to:
            </p>
            <ul>
              <li>
                <p>registering for our websites, apps, or platforms</p>
              </li>
              <li>
                <p>completing or receiving requests for product</p>
              </li>
              <li>
                <p>
                  performing services on behalf of affiliated and non-affiliated
                  third parties
                </p>
              </li>
              <li>
                <p>
                  debugging to identify and repair errors that impair existing
                  intended functionality on our websites, apps, and platforms
                </p>
              </li>
              <li>
                <p>
                  protecting against malicious, deceptive, fraudulent, or
                  illegal activity
                </p>
              </li>
            </ul>

            <br />

            <p>
              <strong>Business Purpose for Collection.</strong> The business
              purposes for which we may collect and/or share the personal
              information of California residents includes, but may not be
              limited to:
            </p>
            <ul>
              <li>
                <p>advertising networks</p>
              </li>
              <li>
                <p>internet service providers</p>
              </li>
              <li>
                <p>data analytics providers</p>
              </li>
              <li>
                <p>service providers</p>
              </li>
              <li>
                <p>operating systems and platforms</p>
              </li>
              <li>
                <p>social medial networks</p>
              </li>
              <li>
                <p>non-affiliated third parties</p>
              </li>
              <li>
                <p>affiliated third parties</p>
              </li>
            </ul>

            <br />

            <p>
              <strong>
                <u>
                  <em>
                    Categories of Personal Information Sold in The Past Year
                  </em>
                </u>
              </strong>
            </p>

            <br />

            <p>
              We have not sold any personal information of California residents
              to any third party in the twelve months preceding the date this
              Privacy Notice was last updated.
              <br />
              <br />
              Are these sites and apps intended for children?
              <br />
              <br />
              Our sites and apps are meant for adults. We do not knowingly
              collect personally identifiable information from children under 18
              without permission from a parent or guardian. If you are a parent
              or legal guardian and think your child under 18 has given us
              information, you can contact us at{" "}
              <a href="mailto:Privacy@tyson.com">Privacy@tyson.com</a>, or call
              toll-free at 1-866-467-8688 and enter service code 262#,{" "}
              <strong>
                <em>or contact us at</em>
              </strong>
            </p>

            <br />

            <p>
              Tyson Foods, Inc. <br />
              Privacy c/o Ethics & Compliance Department <br />
              Mail Code CP006
              <br />
              2200 W. Don Tyson Parkway
              <br />
              Springdale, AR 72765-2020
            </p>

            <br />

            <p>
              Please mark your inquiries "COPPA Information Request." Parents,
              you can learn more about how to protect children's privacy
              on-line. <u>HOW TO PROTECT CHILDREN'S PRIVACY ON-LINE.</u>
              <br />
              <br />
              What are our security measures?
              <br />
              <br />
              The Internet is not 100% secure. We cannot promise that your use
              of our sites will be completely safe. We encourage you to use
              caution when using the Internet. This includes not sharing your
              passwords. We will use our best efforts to create and maintain
              commercially reasonable technical, organizational, and physical
              safeguards, consistent with applicable law, to protect your
              personal information.
              <br />
              <br />
              We keep personal information as long as it is necessary or
              relevant for the practices described in this Policy. We also keep
              information as otherwise required by law.
              <br />
              <br />
              Do we store information in and outside of the United States?
              <br />
              <br />
              Information we maintain may be stored in the United States. If you
              live outside of the United States, you understand and agree that
              we may transfer your information to the United States. This site
              is subject to U.S. laws, which may not afford the same level of
              protection as those in your country.
              <br />
              <br />
              Do we link to third-party sites or services we don't control?
              <br />
              <br />
              If you click on a link to a third-party site, you will be taken to
              a site we do not control. This Policy does not apply to the
              privacy practices of that site. Read the privacy policy of other
              sites carefully. We are not responsible for these third-party
              sites.
            </p>

            <br />

            <strong>
              <p>
                <em>Feel free to contact us if you have more questions.</em>
              </p>
            </strong>
            <br />

            <br />

            <p>
              If you have any questions about this policy or want to correct or
              update your information, please email us at{" "}
              <a href="mailto:Privacy@tyson.com">Privacy@tyson.com</a> or call
              toll-free at 1-866-467-8688 and enter service code 262#. You also
              can contact us at:
            </p>

            <br />

            <p>
              Tyson Foods, Inc. <br />
              Privacy c/o Ethics & Compliance Department <br />
              Mail Code CP006
              <br />
              2200 W. Don Tyson Parkway
              <br />
              Springdale, AR 72765-2020
              <br />
              or 1-866-467-8688 and enter service code 262#.
            </p>

            <br />
            <p>
              When contacting us, please be sure to provide us with your exact
              e-mail address, name, address, and/or telephone number(s) in order
              to provide the information we need to address your request.
            </p>

            <br />

            <p>
              Do we update this Privacy Policy?
              <br />
              From time to time we may change our Privacy Policy. We will notify
              you of any material changes to our Privacy Policy as required by
              law. We will also post an updated copy on our site. Please check
              our site periodically for updates.
            </p>

            <p>©1 Tyson Foods, Inc. All rights reserved.</p>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
